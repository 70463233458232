import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from '@material-tailwind/react';
import React from 'react';
import img2 from '../assets/images/ict/Photo of Mohammad Humayun Kabir.jpg';

const ICT = () => {
  const lang = localStorage.getItem('language');
  return (
    <div>
      <div className="px-0 lg:px-2 mt-2">
        <Card className="rounded-sm w-full">
          {' '}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === 'en' ? 'ICT' : 'আইসিটি'}
          </Typography>
        </Card>
        <hr className="my-3" />
        <div>
          <Card className="w-full flex flex-col max-w-[52rem] lg:flex-row rounded-sm mx-auto">
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 w-72 h-88 relative  shrink-0 mx-auto rounded-r-none rounded-s-sm"
            >
              <img
                src={img2}
                alt="card-image"
                className="bottom-0 lg:absolute mx-auto"
              />
            </CardHeader>
            <CardBody className="py-2">
              <Typography variant="h6" color="gray" className="mb-2 uppercase">
                {lang === 'en' ? 'Assistant Programmer' : ' সহকারী প্রোগ্রামার'}
              </Typography>
              <Typography variant="h4" color="blue-gray" className="mb-2">
                {lang === 'en'
                  ? 'Mohammad Humayun Kabir'
                  : 'মোহাম্মদ হুয়ায়ুন কবির'}
              </Typography>
              <Typography color="gray" className="mb-4 font-normal">
                {lang === 'en'
                  ? 'National Institute of Laboratory Medicine & Referral Center'
                  : 'ন্যাশনাল ইনস্টিটিউট অব ল্যাবরেটরী মেডিসিন এন্ড রেফারেল সেন্টার'}
              </Typography>
              <Typography className="text-sm">
                {' '}
                {lang === 'en'
                  ? 'Sher-E-Bangla, Agargaon, Dhaka-1207, Bangladesh'
                  : 'শের-ই-বাংলা নগর, আগারগাঁও, ঢাকা-১২০৭, বাংলাদেশ'}
              </Typography>
              <Typography className="text-sm">
                {lang === 'en'
                  ? 'Phone: +880 1912-926 049'
                  : ' ফোনঃ +৮৮০ ১৯১২-৯২৬ ০৪৯'}
              </Typography>
              <Typography className="text-sm">
                {lang === 'en' ? 'PEABX: 509' : ' পিইএবিএক্সঃ ৫০৯'}
              </Typography>
              <Typography className="text-sm">
                {lang === 'en' ? 'Room #508' : '  রুম #৫০৮'}
              </Typography>
              <Typography className="text-sm mt-4">
                {lang === 'en' ? 'Email:' : ' ই-মেইলঃ'}{' '}
                <span className="text-red-500 hover:underline hover:text-blue-900 ">
                  <a href="mailto:mhkabirsco@gmail.com">mhkabirsco@gmail.com</a>
                </span>
              </Typography>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ICT;
