import React, { useState } from 'react';

const Accreditation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const openModal = (image) => {
    setModalImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalImage(null);
  };

  const images = [
    '/Pic1_Nilmrc.jpg',
    '/Quality Policy Statement.jpg',
    '/Quality Polity Statement1.jpg',
  ];

  return (
    <div className="flex items-center justify-center m-5">
      <div className="grid grid-cols-1 gap-10">
        {images.map((image, index) => (
          <div key={index} className="relative group">
            <img
              loading="lazy"
              width={400}
              src={image}
              alt={`Image ${index + 1}`}
              className="rounded-lg shadow-lg"
            />
            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 flex items-center justify-center transition-opacity duration-300 rounded-lg">
              <button
                className="opacity-0 group-hover:opacity-100 bg-white text-black px-4 py-2 rounded-lg shadow-md transition-opacity duration-300"
                onClick={() => openModal(image)}
              >
                View
              </button>
            </div>
          </div>
        ))}
      </div>

      {isOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div 
            className="relative bg-white rounded-lg p-4 max-w-3xl w-full shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <img src={modalImage} alt="Modal Pic" className="w-full max-h-[80vh] rounded-lg" />
            <button 
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2 hover:bg-red-700 transition"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Accreditation;

